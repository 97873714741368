.picture {
    width: 170px;
    height: 170px;
    cursor: pointer;
}

.icon img {
    width: 135px;
    height: 130px;
    object-fit: cover;
    border: 7px solid #fff;
    box-sizing: border-box;
}

.picture .file_name {
    padding-top: 10px;
}

.picture_buttons button {
    background-color: rgba(255, 255, 255, 0.702);
    border: 2.5px solid black;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    justify-content: center;
    display: flex;
    align-items: center;
    z-index: 10;
    cursor: pointer;
    transform: translate(6px, -125px)
}

.picture_buttons #next_pic {
    transform: translate(134px, -155px)
}

.picture_buttons polyline {
    stroke-width: 4px;
}

.picture_container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button_container {
    position: absolute;
    width: 100%;
    height: 93%;
    top: 7%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.button_container button {
    border: 0px;
    background-color: transparent;
    cursor: pointer;
    font-weight: bold;
    padding-left: 10%;
    padding-right: 10%;
}

.button_container polyline {
    stroke-width: 3px;
    stroke: rgb(207, 207, 207);
    transition: 0.3s ease-in-out;
}

.button_container button:hover polyline {
    stroke: var(--color-blue);
}

.window_body:has(.picture_container) {
    overflow-y: hidden;
}