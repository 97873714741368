.window_container {
    position: absolute;
    width: 50vw;
    height: 70vh;
    left: 25%;
    top: 15%;
    color: var(--color-fontcolor);
}

.window {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--color-windowbg);
    border-radius: 10px;
    border: 3px solid black;
    font-family: "Roboto Mono";
    font-weight: 500;
    text-align: left;
}

.window_header {
    position: relative;
    height: 30px;
    background-color: var(--color-windowheader);
    border-radius: 10px 10px 0px 0px;
    border-bottom: 3px solid black;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.window_header_button {
    height: 15px;
    width: 15px;
    border-radius: 20px;
    background-color: var(--color-windowheaderbutton);
    margin-left: 10px;
    z-index: 10;
}

.window_header_button.close:hover {
    background-color: #ED6A5E;
}

.window_header_title {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dragbar {
    position: absolute;
    z-index: 100;
    width: 85%;
    height: 7%;
    right: 0px;
}

.window_body {
    height: calc(70vh - 33px);
    overflow-y: scroll;
    scrollbar-color: rebeccapurple green;
}

.window_body p {
    margin: 0px;
    font-size: 16px;
}

.window_body img {
    max-width: 80%;
    max-height: 90%;
}