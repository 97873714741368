.name_animation_wrapper {
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    animation-name: hideanimation;
    animation-fill-mode: forwards;
    animation-duration: 0.1s;
    animation-delay: 5.8s;
    overflow: hidden;
}

.name_animation_wrapper.skip {
    animation-delay: 0.1s;
    animation-duration: 0.5s;
    animation-name: skipanimation;
    animation-fill-mode: forwards;
}

@keyframes skipanimation {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        display: none;
        visibility: hidden;
    }
}

#skip_animation {
    width: 20%;
    height: 8%;
    z-index: 100;
    position: absolute;
    right: 10%;
    bottom: 15%;
    font-size: 1.1rem;
    font-weight: bold;
    border: 3px solid #000;
    background-color: #534FF7;
    cursor: pointer;
    transition: 0.4s ease-in-out;
    animation-name: hide_skip_animation;
    animation-fill-mode: forwards;
    animation-duration: 1s;
    animation-delay: 5s;
}

#skip_animation:hover {
    transform: scale(1.03);
    background-color: #FFC700;
}

@keyframes hide_skip_animation {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        display: none;
    }
}

@keyframes hideanimation {
    from {
        visibility: visible;
    }
    to {
        visibility: hidden;
    }
}

.backdrop {
    width: 100%;
    right: 0px;
    position: absolute;
    height: 100%;
}

.backdrop.b1 {
    background-color: #534FF7;
    animation-name: slidebackdrop;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(.53, .02, .05, .99);
    animation-duration: 1.4s;
    animation-delay: 4.4s;
}

.backdrop.b2 {
    background-color: #FFC700;
    animation-name: slidebackdrop;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(.53, .02, .05, .99);
    animation-duration: 1.4s;
    animation-delay: 4.7s;
}

.backdrop.b3 {
    background-color: #D2197D;
    animation-name: slidebackdrop;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(.53, .02, .05, .99);
    animation-duration: 1.4s;
    animation-delay: 5s;
}

@keyframes slidebackdrop {
    from {
        width: 100%
    }
    to {
        width: 0%;
        visibility: hidden;
    }
}

.name {
    font-size: 5rem;
    font-weight: 800;
    position: absolute;
    letter-spacing: 0.2em;
    transition: 0.5s ease-in-out;
}

.name_bottom {
    font-size: 5rem;
    font-weight: 800;
    -webkit-text-stroke-color: black;
    -webkit-text-stroke-width: 7px;
    letter-spacing: 0.2em;
    position: absolute;
}

.name_animation_wrapper #first {
    animation-name: slide;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.3, 1.9, 0.9, 2.1);
    animation-duration: 3.3s;
    animation-delay: 3.5s;
}

.name_animation_wrapper #second {
    animation-name: slide;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.3, 1.9, 0.9, 2.1);
    animation-duration: 2.8s;
    animation-delay: 3.9s;
}

.name_animation_wrapper #third {
    animation-name: slide;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.3, 1.9, 0.9, 2.1);
    animation-duration: 2.8s;
    animation-delay: 4s;
}

.name_animation_wrapper #forth {
    animation-name: slide;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.3, 1.9, 0.9, 2.1);
    animation-duration: 2.8s;
    animation-delay: 4.1s;
}

.name_animation_wrapper #fifth {
    animation-name: slide;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.3, 1.9, 0.9, 2.1);
    animation-duration: 2.8s;
    animation-delay: 4.2s;
}

.name_animation_wrapper #sixth {
    animation-name: slide;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.3, 1.9, 0.9, 2.1);
    animation-duration: 2.8s;
    animation-delay: 4.3s;
}

@keyframes slide {
    from {
        transform: translate(0px);
    }
    15% {
        transform: translate(3px)
    }
    50% {
        transform: translate(-60px)
    }
    99% {
        transform: translate(940px)
    }
    100% {
        display: none;
    }
}

.first #j {
    animation-name: loading;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
    animation-delay: 0.8s;
}

.first #h {
    animation-name: loading;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
    animation-delay: 1.6s;
}

.first #n {
    animation-name: loading;
    animation-fill-mode: forwards;
    animation-duration: 0.4s;
    animation-delay: 2.4s;
}

.first #y {
    animation-name: loading;
    animation-fill-mode: forwards;
    animation-duration: 0.35s;
    animation-delay: 2.8s;
}

.first #c {
    animation-name: loading;
    animation-fill-mode: forwards;
    animation-duration: 0.3s;
    animation-delay: 3.1s;
}

.first #d {
    animation-name: loading;
    animation-fill-mode: forwards;
    animation-duration: 0.25s;
    animation-delay: 3.2s;
}

.first #i {
    animation-name: loading;
    animation-fill-mode: forwards;
    animation-duration: 0.2s;
    animation-delay: 3.3s;
}

.first #o {
    animation-name: loading;
    animation-fill-mode: forwards;
    animation-duration: 0.2s;
    animation-delay: 3.4s;
}

@keyframes loading {
    0% {
        transform: translate(0px);
        animation-timing-function: ease-in-out;
    }
    100% {
        transform: translate(0px);
        color: white;
    }
}