@import url('https://fonts.googleapis.com/css?family=Inter');
:root {
    --font-family: 'Inter', sans-serif;
    --color-bg: #fff;
    --color-bg: #9FA4B2;
    --color-skin: #E7B18B;
    --color-hat-outline: #1B2C47;
    --color-hat: #24395D;
    --color-hair: #301B0A;
    --color-skin-shade: #DCA883;
    --color-shirt: #353537;
    --color-macbook: #85878E;
    --color-jeans: #181C27;
    --color-jeans-fold: #5E6772;
    --color-shoe: #F4F4F4;
    --color-shoe-outline: #E4E4E4;
    --color-orange: #F4745A;
    --color-green: #0DAB58;
    --color-purple: #534FF7;
    --color-yellow: #FFC700;
    --color-navy: #1E3471;
    --color-skyblue: #84C4F2;
    --color-blue: #0B8CE9;
    --color-pink: #D2197D;
    --color-fontcolor: black;
    --color-windowbg: white;
    --color-windowheader: #EEEFEF;
    --color-windowheaderbutton: #D9D9D9;
    --color-sidebaralternate: #F5F5F5;
    --color-navbarfontcolor: black;
    --color-navbarbg: #EBEBEB;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #EBEBEB;
}

a {
    text-decoration: none;
}

::-webkit-scrollbar {
    display: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}