.terminal_body {
    height: calc(100% - 30px);
    display: flex;
    flex-direction: column;
    gap: 2px;
}

#input {
    /* border-right: 10px solid #1C1C1C;
    animation: cursorblink 1.3s cubic-bezier(.215, .61, .355, 1) forwards infinite; */
    caret-color: transparent;
    background-color: inherit;
    color: inherit;
}

#input_cursor {
    font-size: 1rem;
    position: absolute;
    margin-left: -0.5px;
    animation: cursorblink 1.3s cubic-bezier(.215, .61, .355, 1) forwards infinite;
}

#input:focus,
#input:active {
    outline: none;
}

@keyframes cursorblink {
    50% {
        opacity: 0;
    }
}

.intro1.true {
    opacity: 0;
    animation-name: delay;
    animation-fill-mode: forwards;
    animation-delay: 1s;
}

.input.true {
    opacity: 0;
    animation-name: delay;
    animation-fill-mode: forwards;
    animation-delay: 1.5s;
    transform: translateY(-1px);
}

@keyframes delay {
    100% {
        opacity: 1;
    }
}

.terminal_output {
    white-space: pre-wrap
}

#intro2.false {
    display: none;
}

.cmatrix_container {
    position: fixed;
    top: 51px;
    left: 19px;
    transform: translate(-1rem, -1rem);
    width: calc(100% + 2rem);
    height: calc(100% + 2rem);
    z-index: 200;
}

.cmatrix_container button {
    position: fixed;
    z-index: 10;
    right: 5px;
    top: 5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: rgba(235, 235, 235, 0.348);
    border: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.cmatrix {
    width: 100%;
    height: 100%;
}