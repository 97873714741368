.file,
.folder,
.picture,
.terminal {
    width: 170px;
    height: 170px;
}

.file:hover,
.folder:hover,
.picture:hover,
.terminal:hover {
    outline: 2px solid #0B8CE9;
    cursor: pointer;
}

.file_name {
    font-size: 1rem;
    margin-top: -5px;
    font-family: "Roboto Mono";
    font-weight: 600;
    color: white;
    text-shadow: 1px 1px 2px rgb(128, 128, 128);
}