.navbar {
    width: 100vw;
    grid-row: 1/2;
    background-color: var(--color-navbarbg);
    border-bottom: 3px solid #000;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.navbar p {
    color: var(--color-navbarfontcolor);
    font-family: "Roboto Mono";
    font-weight: 500;
    font-size: 1.1rem;
    margin: 7px;
}

.navbar button {
    background-color: var(--color-navbarbg);
    border: 0px;
    cursor: pointer;
    position: absolute;
    right: 10%;
    padding-top: 0.3rem;
}


/* .navbar p {
    margin-left: 3rem;
    font-weight: 800;
    font-size: 2rem;
    margin-top: 0;
    margin-bottom: 0;
    width: 100px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: #292929;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
} */