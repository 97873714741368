.main {
    display: flex;
    background-color: var(--color-bg);
    position: relative;
    justify-content: center;
    align-items: center;
}

button {
    height: 30px;
}

.block {
    position: absolute;
    width: 100vw;
    transition: 0.6s ease-in-out;
    cursor: pointer;
}

.block_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 65vmin;
    height: 64vmin;
    position: relative;
}


/* v0 ----------------------------------------------------- */

.block.hat-v0 {
    background-color: var(--color-hat);
    height: calc(100vh*3/64);
    top: 0px;
    width: 14%
}

.block.face-v0 {
    background-color: var(--color-skin);
    height: calc(100vh*3/64);
    top: calc(100vh*3/64);
    width: 14%
}

.block.neck-v0 {
    background-color: var(--color-skin-shade);
    height: calc(100vh*2/64);
    top: calc(100vh*6/64);
    width: 14%
}

.block.shirt-v0 {
    background-color: var(--color-shirt);
    height: calc(100vh*9/64);
    top: calc(100vh*8/64);
    width: 14%
}

.block.macbook-v0 {
    background-color: var(--color-macbook);
    height: calc(100vh*5/64);
    top: calc(100vh*17/64);
    width: 14%;
    z-index: 5;
}

.block.jeans-v0 {
    background-color: var(--color-jeans);
    height: calc(100vh*16/64);
    top: calc(100vh*22/64);
    width: 14%
}

.block.jeans-fold-v0 {
    background-color: var(--color-jeans-fold);
    height: calc(100vh*1/64);
    top: calc(100vh*38/64);
    width: 14%
}

.block.leg-v0 {
    background-color: var(--color-skin-shade);
    height: calc(100vh*1/64);
    top: calc(100vh*39/64);
    width: 14%
}

.block.shoe-v0 {
    background-color: var(--color-shoe);
    height: calc(100vh*3/64);
    top: calc(100vh*40/64);
    width: 14%
}

.block.shoe-outline-v0 {
    background-color: var(--color-shoe-outline);
    height: calc(100vh*1/64);
    width: 14%;
    top: calc(100vh*42/64);
}


/* v1 ----------------------------------------------------- */

.block.hat-v1 {
    background-color: var(--color-hat);
    height: calc(100vh*3/64);
    top: 0;
    width: 100%;
}

.block.face-v1 {
    background-color: var(--color-skin);
    height: calc(100vh*3/64);
    top: calc(100vh*3/64);
    width: 100%;
}

.block.neck-v1 {
    background-color: var(--color-skin-shade);
    height: calc(100vh*2/64);
    top: calc(100vh*6/64);
    width: 100%;
}

.block.shirt-v1 {
    background-color: var(--color-shirt);
    height: calc(100vh*9/64);
    top: calc(100vh*8/64);
    width: 100%;
}

.block.macbook-v1 {
    background-color: var(--color-macbook);
    height: calc(100vh*5/64);
    top: calc(100vh*17/64);
    width: 100%;
}

.block.jeans-v1 {
    background-color: var(--color-jeans);
    height: calc(100vh*16/64);
    top: calc(100vh*22/64);
    width: 100%;
}

.block.jeans-fold-v1 {
    background-color: var(--color-jeans-fold);
    height: calc(100vh*1/64);
    top: calc(100vh*38/64);
    width: 100%;
}

.block.leg-v1 {
    background-color: var(--color-skin-shade);
    height: calc(100vh*1/64);
    top: calc(100vh*39/64);
    width: 100%;
}

.block.shoe-v1 {
    background-color: var(--color-shoe);
    height: calc(100vh*3/64);
    top: calc(100vh*40/64);
    width: 100%;
}

.block.shoe-outline-v1 {
    background-color: var(--color-shoe-outline);
    height: calc(100vh*1/64);
    width: 100%;
    top: calc(100vh*42/64);
}


/* v2 ----------------------------------------------------- */

[class*="-v2"] {
    border-radius: 100rem;
}

.block.hat-v2 {
    background-color: var(--color-purple);
    height: calc(100vh*3/64);
    top: 0;
    width: 58%;
    transform: translateX(5%);
}

.block.face-v2 {
    background-color: var(--color-orange);
    height: calc(100vh*3/64);
    top: calc(100vh*4/64);
    width: 58%;
    transform: translateX(30%);
}

.block.neck-v2 {
    background-color: var(--color-yellow);
    height: calc(100vh*2/64);
    top: calc(100vh*5/64);
    width: 37%;
    transform: translateX(-90%);
}

.block.shirt-v2 {
    background-color: var(--color-shirt);
    height: calc(100vh*6.5/64);
    top: calc(100vh*8/64);
    width: 79%;
    transform: translateX(5%);
}

.block.macbook-v2 {
    background-color: var(--color-green);
    height: calc(100vh*5/64);
    top: calc(100vh*15.5/64);
    width: 60%;
    transform: translateX(-35%);
}

.block.jeans-v2 {
    background-color: var(--color-navy);
    height: calc(100vh*14/64);
    top: calc(100vh*21.5/64);
    width: 100%;
    transform: translateX(0%);
}

.block.jeans-fold-v2 {
    background-color: var(--color-jeans-fold);
    height: calc(100vh*2.5/64);
    top: calc(100vh*36.5/64);
    width: 62%;
    transform: translateX(30%);
}

.block.leg-v2 {
    background-color: var(--color-pink);
    height: calc(100vh*2.5/64);
    top: calc(100vh*36.5/64);
    width: 33%;
    transform: translateX(-90%);
}

.block.shoe-v2 {
    background-color: var(--color-shoe);
    height: calc(100vh*3/64);
    top: calc(100vh*40/64);
    width: 71%;
    transform: translateX(5%);
}

.block.shoe-outline-v2 {
    background-color: var(--color-skyblue);
    height: calc(100vh*3/64);
    top: calc(100vh*40/64);
    width: calc(100vh*3/64);
    border-radius: 10rem;
    transform: translateX(-550%);
}


/* v3 ----------------------------------------------------- */

[class*="-v3"] {
    border-radius: 70px;
}

.block.hat-v3 {
    background-color: var(--color-purple);
    top: 0;
    width: 64.7%;
    height: 33%;
    transform: translateX(calc(-64vmin*0.175));
    border-top-left-radius: 0px;
}

.block.face-v3 {
    background-color: var(--color-orange);
    top: 40%;
    width: 27.5%;
    height: 13.3333%;
    transform: translateX(calc(64vmin*0.3625));
    border-top-left-radius: 0px;
}

.block.neck-v3 {
    background-color: var(--color-yellow);
    height: 21%;
    top: 12%;
    width: 21%;
    transform: translateX(-183%);
}

.block.shirt-v3 {
    background-color: var(--color-shirt);
    top: 0;
    width: 35%;
    height: 40%;
    transform: translateX(calc(64vmin*0.33));
    border-bottom-right-radius: 0px;
}

.block.macbook-v3 {
    background-color: var(--color-green);
    top: 33%;
    width: 40%;
    transform: translateX(-35%);
    height: 32%;
    transform: translateX(calc(-64vmin*0.3));
    border-bottom-right-radius: 0px;
}

.block.jeans-v3 {
    background-color: var(--color-navy);
    height: 51.5%;
    top: 53.333%;
    width: 59%;
    transform: translateX(calc(64vmin*0.205));
    border-bottom-left-radius: 0px;
}

.block.jeans-fold-v3 {
    background-color: var(--color-jeans-fold);
    height: 20.3%;
    top: 33%;
    width: 31.6%;
    transform: translateX(calc(64vmin*0.061));
}

.block.leg-v3 {
    background-color: var(--color-pink);
    height: 20%;
    top: 52%;
    width: 20%;
    transform: translate(4%, 163%);
}

.block.shoe-v3 {
    background-color: var(--color-shoe);
    height: 40%;
    top: 65%;
    width: 40%;
    transform: translateX(calc(-64vmin*0.3));
}

.block.shoe-outline-v3 {
    background-color: var(--color-skyblue);
    height: 25%;
    top: 80%;
    width: 25%;
    border-radius: 10rem;
    transform: translateX(calc(-64vmin*0.224));
}


/* v4 ----------------------------------------------------- */

.block.hat-v4 {
    background-color: var(--color-hat);
    height: calc(100vh*1.03/64);
    top: 0px;
    width: 14%;
    transform: translate(calc(64vmin*1*0.0233), calc(64vmin*1*0.0231));
}

.block.face-v4 {
    background-color: var(--color-skin);
    height: calc(100vh*3/64);
    top: calc(100vh*3/64);
    width: 9.333%;
    transform: translateX(calc(64vmin*0.0233));
}

.block.neck-v4 {
    background-color: var(--color-skin-shade);
    height: calc(100vh*2/64);
    top: calc(100vh*6/64);
    width: 7%;
    transform: translateX(calc(64vmin*0.01165));
}

.block.shirt-v4 {
    background-color: var(--color-shirt);
    height: calc(100vh*13.1/64);
    top: calc(100vh*8/64);
    width: 11.67%;
    transform: translateX(calc(64vmin*0.01165));
}

.block.macbook-v4 {
    background-color: var(--color-macbook);
    height: calc(100vh*6/64);
    top: calc(100vh*17/64);
    width: 23.33%;
    transform: translateX(calc(64vmin*0.0233));
    border-radius: 3px;
    z-index: 5;
}

.block.jeans-v4 {
    background-color: var(--color-jeans);
    height: calc(100vh*16/64);
    top: calc(100vh*22/64);
    width: 9.333%
}

.block.jeans-fold-v4 {
    background-color: var(--color-jeans-fold);
    height: calc(100vh*1/64);
    top: calc(100vh*38/64);
    width: 14%;
    z-index: 5
}

.block.leg-v4 {
    background-color: var(--color-skin-shade);
    height: calc(100vh*1/64);
    top: calc(100vh*39/64);
    width: 2.33%;
    transform: translateX(calc(-64vmin*0.01165));
}

.block.shoe-v4 {
    background-color: var(--color-shoe);
    height: calc(100vh*2.9/64);
    top: calc(100vh*40/64);
    width: 11.7%;
    transform: translateX(calc(-64vmin*0.01165));
}

.block.shoe-outline-v4 {
    background-color: var(--color-shoe-outline);
    height: calc(100vh*1/64);
    width: 14%;
    top: calc(100vh*42/64);
}


/* v5 ----------------------------------------------------- */

[class*="-v5"] {
    border-radius: 0px;
}

.block.hat-v5 {
    background-color: var(--color-purple);
    top: 0;
    width: 20%;
    height: 20%;
    transform: translate(72%, 425%);
    border-top-left-radius: 0px;
}

.block.face-v5 {
    background-color: var(--color-orange);
    top: 40%;
    width: 29%;
    height: 25%;
    transform: translateX(-121%);
    border-top-left-radius: 0px;
}

.block.neck-v5 {
    background-color: var(--color-yellow);
    height: 15%;
    top: 25%;
    width: 26.5%;
    transform: translateX(-32%);
}

.block.shirt-v5 {
    background-color: var(--color-shirt);
    top: 50%;
    width: 45%;
    height: 55%;
    transform: translateX(60%);
    border-bottom-right-radius: 0px;
}

.block.macbook-v5 {
    background-color: var(--color-green);
    top: 40%;
    width: 26.5%;
    height: 65%;
    transform: translateX(-32%);
    border-bottom-right-radius: 0px;
}

.block.jeans-v5 {
    background-color: var(--color-navy);
    height: 25%;
    top: 0%;
    width: 60%;
    transform: translateX(calc(64vmin*0.2));
    border-bottom-left-radius: 0px;
}

.block.jeans-fold-v5 {
    background-color: var(--color-jeans-fold);
    height: 40%;
    top: 0%;
    width: 45%;
    transform: translateX(-60%);
}

.block.leg-v5 {
    background-color: var(--color-pink);
    height: 25%;
    top: 25%;
    width: 45%;
    transform: translate(60%, 0%);
}

.block.shoe-v5 {
    background-color: var(--color-shoe);
    height: 40%;
    top: 65%;
    width: 29%;
    transform: translateX(-121%);
}

.block.shoe-outline-v5 {
    background-color: var(--color-skyblue);
    height: 20%;
    top: 65%;
    width: 20%;
    transform: translateX(72%);
}


/* v6 ----------------------------------------------------- */

[class*="-v6"] {
    border-radius: 0px;
}

.block.hat-v6 {
    background-color: var(--color-purple);
    top: 80%;
    width: 25%;
    height: 25%;
    transform: translateX(-30%);
}

.block.face-v6 {
    background-color: var(--color-orange);
    top: 62%;
    width: 27.5%;
    height: 18%;
    transform: translateX(30%);
}

.block.neck-v6 {
    background-color: var(--color-yellow);
    height: 15%;
    top: 17%;
    transform: translateX(-83%);
    width: 15%;
}

.block.shirt-v6 {
    background-color: var(--color-shirt);
    top: 17%;
    width: 45%;
    height: 88%;
    transform: translateX(60%);
    border-bottom-right-radius: 0px;
}

.block.macbook-v6 {
    background-color: var(--color-green);
    top: 17%;
    width: 35%;
    height: 25%;
    transform: translateX(35%);
}

.block.jeans-v6 {
    background-color: var(--color-navy);
    height: 17%;
    top: 0%;
    width: 99.5%;
    transform: translateX(0%);
    border-bottom-left-radius: 0px;
    transform: translateX(-0.2%);
}

.block.jeans-fold-v6 {
    background-color: var(--color-jeans-fold);
    height: 63%;
    top: 17%;
    width: 45%;
    transform: translateX(-61%);
}

.block.leg-v6 {
    background-color: var(--color-pink);
    height: 20%;
    top: 42%;
    width: 30%;
    transform: translateX(0%);
}

.block.shoe-v6 {
    background-color: var(--color-shoe);
    height: 25%;
    top: 80%;
    width: 50%;
    transform: translateX(-50%);
}

.block.shoe-outline-v6 {
    background-color: var(--color-skyblue);
    height: 25%;
    top: 80%;
    width: 20%;
    transform: translateX(-145%);
    border-top-left-radius: 10rem;
    border-bottom-left-radius: 10rem;
}


/* outline ----------------------------------------------------- */

.outline {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    transition: .9s ease-in-out;
}

.arm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    transition: .9s ease-in-out;
    z-index: 10;
}

.block.shoe-outline {
    background-color: var(--color-shoe-outline);
}

.block.shirt-outline1 {
    background-color: #000;
    height: calc(100vh*13/64);
    width: 2.333%;
    transform: translate(calc(-64vmin*2.48*0.0233), calc(-64vmin*5.2*0.0233))
}

.block.shirt-outline2 {
    background-color: #000;
    height: 2.333%;
    width: 2.333%;
    transform: translate(calc(-64vmin*1.5*0.0233), calc(-64vmin*12.5*0.0233))
}

.block.shirt-outline3 {
    background-color: #000;
    height: calc(100vh*1/64);
    width: 4.666%;
    transform: translate(calc(64vmin*0*0.0233), calc(-64vmin*13.6*0.0232))
}

.block.shirt-outline4 {
    background-color: #000;
    height: calc(100vh*1/64);
    width: 4.666%;
    transform: translate(calc(64vmin*2*0.0233), calc(-64vmin*12.55*0.0233))
}

.block.shirt-outline5 {
    background-color: #000;
    height: calc(100vh*13.15/64);
    width: 2.328%;
    transform: translate(calc(64vmin*3.49*0.0232), calc(-64vmin*5.2*0.0232))
}

.block.shirt-outline6 {
    background-color: #000;
    height: calc(100vh*1/64);
    width: 11.65%;
    transform: translate(calc(64vmin*0.52*0.0233), calc(64vmin*1.1*0.0233))
}

.block.shoulder-outline1 {
    background-color: #000;
    height: calc(100vh*3.1/64);
    width: 2.34%;
    transform: translate(calc(-64vmin*1.5*0.023), calc(-64vmin*9.4*0.0233))
}

.block.shoulder-outline2 {
    background-color: #000;
    height: calc(100vh*1/64);
    width: 4.666%;
    transform: translate(calc(64vmin*0*0.0233), calc(-64vmin*11.5*0.0233))
}

.block.shoulder-outline3 {
    background-color: #000;
    height: calc(100vh*1.05/64);
    width: 2.333%;
    transform: translate(calc(64vmin*1.5*0.0233), calc(-64vmin*10.4*0.0233))
}

.block.shoulder-outline4 {
    background-color: #000;
    height: calc(100vh*2/64);
    width: 2.333%;
    transform: translate(calc(64vmin*2.5*0.0233), calc(-64vmin*8.8*0.0233))
}

.block.shoulder-outline5 {
    background-color: #000;
    height: calc(100vh*1/64);
    width: 6.8%;
    transform: translate(calc(64vmin*0.5*0.0233), calc(-64vmin*7.25*0.0233))
}

.block.leg-outline1 {
    background-color: #000;
    height: calc(100vh*1/64);
    width: 2.333%;
    transform: translate(calc(-64vmin*1.5*0.0233), calc(64vmin*19.9*0.0233))
}

.block.leg-outline2 {
    background-color: #000;
    height: calc(100vh*1/64);
    width: 2.333%;
    transform: translate(calc(64vmin*0.5*0.0233), calc(64vmin*19.9*0.0233))
}

.block.jeans-outline1 {
    background-color: #000;
    height: calc(100vh*16.2/64);
    width: 2.34%;
    transform: translate(calc(64vmin*2.5*0.0233), calc(64vmin*9.95*0.0233))
}

.block.jeans-outline2 {
    background-color: #000;
    height: calc(100vh*16.2/64);
    width: 2.34%;
    transform: translate(calc(-64vmin*2.48*0.0235), calc(64vmin*9.95*0.0233))
}

.block.hat-outline1 {
    background-color: var(--color-hat-outline);
    height: calc(100vh*1/64);
    width: 2.333%;
    transform: translate(calc(-64vmin*1.55*0.023), calc(-64vmin*15.7*0.0233))
}

.block.hat-outline2 {
    background-color: var(--color-hat-outline);
    height: calc(100vh*3/64);
    width: 2.333%;
    transform: translate(calc(-64vmin*2.55*0.0227), calc(-64vmin*17.8*0.0233))
}

.block.hat-outline3 {
    background-color: var(--color-hat-outline);
    height: calc(100vh*1.08/64);
    width: 2.34%;
    transform: translate(calc(-64vmin*1.55*0.023), calc(-64vmin*19.9*0.0233))
}

.block.hat-outline4 {
    background-color: var(--color-hat-outline);
    height: calc(100vh*1/64);
    width: 4.69%;
    transform: translate(calc(-64vmin*0*0.0233), calc(-64vmin*21*0.02328))
}

.block.shoe-outline1 {
    background-color: var(--color-shoe-outline);
    height: calc(100vh*3/64);
    width: 2.338%;
    transform: translate(calc(-64vmin*2.51*0.0233), calc(64vmin*22*0.0233))
}


/* .block.shoe-outline2 {
    background-color: var(--color-shoe-outline);
    height: calc(100vh*1/64);
    width: 11.69%;
    transform: translate(calc(64vmin*0.5*0.02326), calc(64vmin*23.1*0.02325))
} */

.block.shoe-outline3 {
    background-color: var(--color-shoe-outline);
    height: calc(100vh*1.1/64);
    width: 2.34%;
    transform: translate(calc(64vmin*1.5*0.02335), calc(64vmin*21*0.0233))
}

.block.shoe-outline4 {
    background-color: var(--color-shoe-outline);
    height: calc(100vh*1/64);
    width: 2.36%;
    transform: translate(calc(64vmin*2.5*0.0236), calc(64vmin*22.05*0.02335))
}


/* .block.shoe-outline5 {
    background-color: var(--color-bg);
    height: calc(100vh*1.1/64);
    width: 2.39%;
    transform: translate(calc(64vmin*2.53*0.0233), calc(64vmin*20.96*0.02334))
} */

.block.hat-1 {
    background-color: var(--color-hat);
    height: calc(100vh*3.1/64);
    width: 2.34%;
    transform: translate(calc(-64vmin*1.55*0.023), calc(-64vmin*17.8*0.0233))
}

.block.hat-2 {
    background-color: var(--color-hat);
    height: calc(100vh*2.97/64);
    width: 2.34%;
    transform: translate(calc(-64vmin*0.55*0.023), calc(-64vmin*18.9*0.0233))
}

.block.hat-3 {
    background-color: var(--color-hat);
    height: calc(100vh*1.1/64);
    width: 2.4%;
    transform: translate(calc(64vmin*0.49*0.02), calc(-64vmin*18.9*0.02335));
}

.block.hair-1 {
    background-color: var(--color-hair);
    height: calc(100vh*1/64);
    width: 2.35%;
    transform: translate(calc(64vmin*0.45*0.02333), calc(-64vmin*17.85*0.02333))
}

.block.hair-2 {
    background-color: var(--color-hair);
    height: calc(100vh*1.08/64);
    width: 4.92%;
    transform: translate(calc(64vmin*2*0.0233), calc(-64vmin*18.9*0.02327));
}

.block.hand-1 {
    background-color: #000;
    height: calc(100vh*6.1/64);
    width: 2.34%;
    transform: translate(calc(-64vmin*0.5*0.021), calc(-64vmin*3.55*0.0233));
}

.block.hand-2 {
    background-color: #000;
    height: calc(100vh*3/64);
    width: 2.333%;
    transform: translate(calc(64vmin*0.5*0.0233), calc(64vmin*1.2*0.0233));
}

.block.hand-3 {
    background-color: #000;
    height: calc(100vh*1/64);
    width: 2.333%;
    transform: translate(calc(64vmin*1.53*0.0233), calc(64vmin*3.3*0.0233));
}

.block.hand-4 {
    background-color: #000;
    height: calc(100vh*5.1/64);
    width: 2.34%;
    transform: translate(calc(64vmin*1.5*0.0234), calc(-64vmin*4.08*0.0232));
}

.block.hand-5 {
    background-color: #000;
    height: calc(100vh*3/64);
    width: 2.4%;
    transform: translate(calc(64vmin*2.5*0.0228), calc(64vmin*0.15*0.0233));
}

.block.hand-6 {
    background-color: #000;
    height: calc(100vh*2.07/64);
    width: 2.34%;
    transform: translate(calc(64vmin*3.45*0.0234), calc(64vmin*2.75*0.0233));
}

.block.hand-fill-1 {
    background-color: var(--color-skin);
    height: calc(100vh*6/64);
    width: 2.34%;
    transform: translate(calc(64vmin*0.5*0.02333), calc(-64vmin*3.55*0.0233));
}

.block.hand-fill-2 {
    background-color: var(--color-skin);
    height: calc(100vh*3.95/64);
    width: 2.345%;
    transform: translate(calc(64vmin*1.5*0.02333), calc(64vmin*0.65*0.0233));
}

.block.hand-fill-3 {
    background-color: var(--color-skin);
    height: calc(100vh*2/64);
    width: 2.33%;
    transform: translate(calc(64vmin*2.5*0.0233), calc(64vmin*2.75*0.0233));
}