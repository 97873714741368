.document-viewer {
    display: flex;
    height: 100%;
}

.window_sidebar {
    width: 40%;
    height: 100%;
    border-right: 3px solid black;
}

.window_info {
    width: 60%;
}

.window_subheader {
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 3px solid black;
}

.window_sidebar-file-container {
    padding: 0.5rem;
    overflow-y: scroll;
    height: 85%;
}

.window_sidebar-file-container::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.window_sidebar-file {
    height: 40px;
    padding-left: 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 7px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.window_sidebar-file img {
    width: 25px;
    margin-right: 1rem;
}

.window_sidebar-file:nth-child(odd) {
    background-color: var(--color-sidebaralternate);
}

.window_info-container {
    overflow-y: scroll;
    height: 85%;
}

.window_info-container p {
    margin: 1rem;
}

.project_image {
    margin: 1rem;
}

.project_image img {
    max-width: 100%;
    border-radius: 10px;
    filter: drop-shadow(5px 5px px rgba(0, 0, 0, 0.371));
}